import React, {useEffect, useState} from 'react';
import {AlertTriangle} from 'lucide-react';
import {FaDiscord, FaTwitter} from 'react-icons/fa';


const MaintenancePage = () => {
    const [dots, setDots] = useState('.');
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prev => prev.length >= 3 ? '.' : prev + '.');
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="min-h-screen bg-slate-900 flex flex-col items-center justify-center p-4 text-white">
            <div className="absolute inset-0 opacity-20 bg-cover bg-center"/>
            <div className="relative z-10 max-w-2xl w-full">
                <div className="flex justify-center mb-8">
                    <AlertTriangle className="w-24 h-24 text-red-500 animate-pulse"/>
                </div>

                <div className="bg-slate-800/80 p-8 rounded-lg backdrop-blur-sm border border-slate-700 shadow-xl">
                    <h1 className="text-4xl font-bold text-center mb-6 text-red-500">
                        Maintenance en cours{dots}
                    </h1>

                    <div className="space-y-6 text-center">
                        <p className="text-xl text-slate-300">
                            Notre serveur est actuellement en maintenance pour améliorer votre expérience de jeu.
                        </p>

                        <div className="p-4 bg-slate-900/50 rounded-lg">
                            <div className="space-y-2">
                                <p className="text-lg text-slate-300">
                                    Pendant ce temps, vous pouvez :
                                </p>
                                <ul className="text-slate-400 space-y-2">
                                    <li>• Rejoindre notre communauté Discord</li>
                                    <li>• Consulter les nouveautés à venir</li>
                                    <li>• Suivre l'avancement sur nos réseaux</li>
                                </ul>
                            </div>
                        </div>

                        <div className="space-y-2">
                            <p className="text-slate-300">
                                Suivez le status du serveur sur :
                            </p>
                            <div className="flex justify-center space-x-4">
                                <a href="https://discord.elyrion.fr"
                                   className="flex items-center px-4 py-2 bg-[#5865F2] hover:bg-[#4854c7] rounded transition-transform transform hover:-translate-y-1">
                                    <FaDiscord size={30} className="text-white mr-2"/> Discord
                                </a>
                                <a href="https://twitter.com/your_twitter_handle"
                                   className="flex items-center px-4 py-2 bg-[#1DA1F2] hover:bg-[#1a8cd8] rounded transition-transform transform hover:-translate-y-1">
                                    <FaTwitter size={30} className="text-white mr-2"/> Twitter
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 text-center space-y-2 text-slate-400">
                    <p>elyrion.fr - Le meilleur des mini-jeux !</p>
                    <p className="text-sm">© {currentYear} Elyrion. Tous droits réservés.</p>
                </div>
            </div>
        </div>
    );
};

export default MaintenancePage;