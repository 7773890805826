import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
    BrowserRouter,
    Route, Routes
} from "react-router-dom";
import MaintenancePage from "./MaintenancePage";

const root: ReactDOM.Root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/*" element={<MaintenancePage />}/>
        </Routes>
    </BrowserRouter>
);
